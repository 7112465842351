import * as yup from 'yup';

export const loginValidation = yup.object().shape({
  email: yup
    .string()
    .email('zadejte prosím email v platném formátu')
    .required('potřeba zadat'),
  password: yup
    .string()
    .required('potřeba zadat')
    .min(6, 'heslo musí mít minimálně 6 znaků'),
});
