import { FC } from 'react';
import { Icon } from '../Icon/Icon';
import { LinkedInIcon } from '../Icon/Icons/LInkedIn.icon';
import { GoogleIcon } from '../Icon/Icons/Google.icon';
import * as React from 'react';
import styled from 'styled-components';
import { FacebookIcon } from '../Icon/Icons/Facebook.icon';

export const SocialLogin: FC = () => {
  const options = [
    { link: 'linkedin', icon: LinkedInIcon },
    { link: 'google', icon: GoogleIcon },
    { link: 'facebook', icon: FacebookIcon },
  ];

  return (
    <GridContainer>
      {options.map((option) => (
        <a
          key={option.link}
          href={`${process.env.NEXT_PUBLIC_API_URL}/api/v1/auth/${option.link}?domain=${window.location.href}`}
        >
          <SsoPaper>
            <Icon size={34} Component={option.icon} />
          </SsoPaper>
        </a>
      ))}
    </GridContainer>
  );
};

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 32px;
  justify-content: center;
`;

const SsoPaper = styled.div`
  height: 50px;
  width: 62px;
  border-radius: 17px;
  background-color: #ffffff;
  box-shadow: 3px 6px 9px 2px rgba(0, 0, 0, 0.08);
  display: grid;
  place-items: center;
  cursor: pointer;
`;
