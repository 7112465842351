import { gql } from '@apollo/client';

import { USER_FRAGMENT } from '../../../apollo/fragments/user.fragment';

export const LOGIN = gql`
  mutation loginMutation($email: String!, $password: String!) {
    login(data: { email: $email, password: $password }) {
      message
      code
      success
      accessToken
      refreshToken
      expiresIn
      refreshExpiresIn
      tokenType
      user {
        ...user
      }
    }
  }

  ${USER_FRAGMENT}
`;
