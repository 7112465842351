import { FC } from 'react';
import { Form, Formik } from 'formik';
import { FormikTextInput } from '../../../common/components/inputs/TextInput';
import { Button } from '@mui/material';
import { PasswordField } from '../../../common/components/inputs/PasswordField';
import { MutationFunction } from '@apollo/client/react/types/types';
import {
  loginMutation,
  loginMutationVariables,
} from '../gql/__generated__/loginMutation';
import { loginValidation } from './validation.schema';
import { t } from '@lingui/macro';
import { FormikSubmitHandler } from '../../../common/types/commonTypes';

type Props = {
  mutate: MutationFunction<loginMutation, loginMutationVariables>;
};

export const LoginForm: FC<Props> = ({ mutate }) => {
  const initialValues = {
    email: '',
    password: '',
  };

  const handleSubmit: FormikSubmitHandler<loginMutationVariables> = async (
    values,
    { setFieldError }
  ) => {
    const result = await mutate({
      variables: values,
    });

    if (!result.data.login.success) {
      setFieldError(
        'password',
        t`Chybně zadané heslo nebo email
`
      );
    }

    return result;
  };

  const getButtonLabel = (isSubmitting: boolean): string =>
    isSubmitting ? t`Přihlašování` : t`Přihlásit se`;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={loginValidation}
    >
      {({ isSubmitting }) => (
        <Form>
          <FormikTextInput name="email" type="email" label={t`Váš email`} />

          <PasswordField name="password" label={t`Heslo`} />

          <Button
            color="success"
            variant="contained"
            size="large"
            type="submit"
            disabled={isSubmitting}
            fullWidth
          >
            {getButtonLabel(isSubmitting)}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
