import { default as React, FC, useState } from 'react';
import {
  InputAdornment,
  IconButton,
  FormControl,
  OutlinedInput,
} from '@mui/material';

import { useField } from 'formik';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { InputLabelCustom, InputWrapper } from '../UI';
import { ErrorLabel } from './ErrorLabel';

type Props = {
  label: string;
  name: string;
  helperText?: string;
};

export const PasswordField: FC<Props> = (props) => {
  const { name, label, ...rest } = props;
  const [field, meta] = useField(name);

  const { onChange, value, onBlur } = field;
  const { error, touched } = meta;
  const hasError = !!error && touched;

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const togglePassword = () => {
    setShowPassword((prevState) => !prevState);
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <InputWrapper>
      <InputLabelCustom>{label}</InputLabelCustom>
      <FormControl variant="outlined" fullWidth error={hasError}>
        <OutlinedInput
          name={name}
          type={showPassword ? 'text' : 'password'}
          value={value}
          error={hasError}
          onBlur={onBlur}
          onChange={onChange}
          {...rest}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={togglePassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      {hasError && <ErrorLabel error={error} />}
    </InputWrapper>
  );
};
