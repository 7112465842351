import styled from 'styled-components';
import { colors } from '../../styles/colors';

export const Divider = styled.div`
  margin-top: 16px;
  position: relative;
  background-color: transparent;
  display: flex;
  justify-content: center;

  .text {
    position: relative;
    width: max-content;
    padding: 0 24px;
    background-color: ${colors.cardLightBlue};
    z-index: 10;
  }

  &:after {
    position: absolute;
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    top: 50%;
    background-color: rgba(9, 60, 143, 0.15);
    z-index: 5;
  }
`;
