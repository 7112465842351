import { FC } from 'react';
import { MainLayout } from '../../common/containers/MainLayout/MainLayout';
import { CardBlue } from '../../common/components/UI';
import Link from 'next/link';
import { Routes } from '../../common/enums/routes';
import Button from '@mui/material/Button';
import * as React from 'react';
import { Typography, Link as MuiLink } from '@mui/material';
import styled from 'styled-components';
import { LoginForm } from './LoginForm';
import { Divider } from '../../common/components/auth/UI';
import { SocialLogin } from '../../common/components/auth/SocialLogin';
import { useMutation } from '@apollo/client';
import { LOGIN } from './gql/login.mutation';
import {
  loginMutation,
  loginMutationVariables,
} from './gql/__generated__/loginMutation';
import { Trans } from '@lingui/macro';
import { useRouter } from 'next/router';

const LoginPage: FC = () => {
  const router = useRouter();

  const [mutate] = useMutation<loginMutation, loginMutationVariables>(LOGIN, {
    onCompleted: async (data) => {
      if (data.login.success) {
        await router.push(
          Routes.Auth + `/${data.login.accessToken}/${data.login.refreshToken} `
        );
      }
    },
  });

  return (
    <MainLayout title="Přihlášení" protectedRoute={false}>
      <Typography variant="h2" align="center">
        <Trans>Přihlásit se </Trans>
      </Typography>

      <RegistrationBlueCard>
        <SocialLogin />

        <Divider>
          <Typography className="text">
            <Trans>nebo</Trans>
          </Typography>
        </Divider>

        <LoginForm mutate={mutate} />

        <Link href={Routes.Registration}>
          <MuiLink align="center" variant="body2" fontWeight={500}>
            <Trans>Vytvořit účet</Trans>
          </MuiLink>
        </Link>

        <Link href={Routes.AuthRequestPasswordReset}>
          <MuiLink align="center" variant="body2" fontWeight={500}>
            <Trans>Zapomenuté heslo?</Trans>
          </MuiLink>
        </Link>
      </RegistrationBlueCard>
    </MainLayout>
  );
};

export default LoginPage;

const RegistrationBlueCard = styled(CardBlue)`
  margin-top: 2rem;
  padding: 3rem 1rem;
  display: grid;
  grid-template-columns: minmax(100px, 400px);
  grid-gap: 16px;
  justify-content: center;
`;
