import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import { colors } from '../../styles/colors';
import { FC } from 'react';

type Props = {
  error: string;
};
export const ErrorLabel: FC<Props> = ({ error }) => {
  if (error) return <StyledTypography>{error}</StyledTypography>;

  return null;
};

const StyledTypography = styled(Typography)`
  color: ${colors.white};
  background-color: ${colors.error};
  font-size: 12px;
  padding: 0px 8px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;
